import moment from 'moment'
import {
	dniLengthErrorMsg,
	dobInvalidErrorMsg,
	nameLengthErrorMsg,
	passportCharacterErrorMsg,
	passportLengthErrorMsg,
	wsCharacterErrorMsg,
	wsLengthErrorMsg,
} from './validationErrorsMsg'

export function nameValidLength(value: string) {
	if (typeof value !== 'string') return
	return value.length >= 2 && value.length <= 72
}

export function dniValidLength(value: string) {
	if (typeof value !== 'string') return
	return value.length >= 7 && value.length <= 10
}
export const passportRegex = /^[A-Z0-9]+$/
export function passportValidLength(value: string) {
	if (typeof value !== 'string') return
	return value.length >= 8 && value.length <= 10
}

export const wsRegex = /^[0-9]+$/i
export function wsValidLength(value: string) {
	if (typeof value !== 'string') return
	return value.length >= 9 && value.length <= 12
}

export function validateUserAge(dob: string, dependant?: string | boolean) {
	if (typeof dob !== 'string') return
	const edad = moment().year() - moment(dob).year()
	if (!dependant) {
		if (edad <= 14) {
			return 'Debe ser mayor que 13 años'
		}
	}
	if (edad >= 130) {
		return 'Creemos que es un error que tenga más de 130 años'
	}
}
export function checkInvalidDate(dob: string) {
	if (typeof dob !== 'string') return
	if (moment(dob).isAfter(moment())) return dobInvalidErrorMsg
}

export function checkValidName(value: string | undefined) {
	if (typeof value !== 'string' || !value) return
	if (!nameValidLength(value)) return nameLengthErrorMsg
}

export function checkValidWs(value: string) {
	if (typeof value !== 'string' || !value) return
	if (!wsRegex.test(value)) return wsCharacterErrorMsg
	if (!wsValidLength(value)) return wsLengthErrorMsg
}

export function checkValidDni(value: string, isDNI?: boolean, isPassport?: boolean) {
	if (typeof value !== 'string') return
	if (isPassport && !passportValidLength(value)) return passportLengthErrorMsg
	if (isPassport && !passportRegex.test(value)) return passportCharacterErrorMsg
	if (isDNI && !dniValidLength(value)) return dniLengthErrorMsg
}
